/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$icon-card-active-name: 'icon-card-active';
$icon-card-active-x: 215px;
$icon-card-active-y: 0px;
$icon-card-active-offset-x: -215px;
$icon-card-active-offset-y: 0px;
$icon-card-active-width: 19px;
$icon-card-active-height: 16px;
$icon-card-active-total-width: 280px;
$icon-card-active-total-height: 22px;
$icon-card-active-image: '../img/sprite/sprite.png';
$icon-card-active: (215px, 0px, -215px, 0px, 19px, 16px, 280px, 22px, '../img/sprite/sprite.png', 'icon-card-active', );
$icon-card-name: 'icon-card';
$icon-card-x: 194px;
$icon-card-y: 0px;
$icon-card-offset-x: -194px;
$icon-card-offset-y: 0px;
$icon-card-width: 19px;
$icon-card-height: 16px;
$icon-card-total-width: 280px;
$icon-card-total-height: 22px;
$icon-card-image: '../img/sprite/sprite.png';
$icon-card: (194px, 0px, -194px, 0px, 19px, 16px, 280px, 22px, '../img/sprite/sprite.png', 'icon-card', );
$icon-checkbox-off-name: 'icon-checkbox-off';
$icon-checkbox-off-x: 46px;
$icon-checkbox-off-y: 0px;
$icon-checkbox-off-offset-x: -46px;
$icon-checkbox-off-offset-y: 0px;
$icon-checkbox-off-width: 16px;
$icon-checkbox-off-height: 15px;
$icon-checkbox-off-total-width: 280px;
$icon-checkbox-off-total-height: 22px;
$icon-checkbox-off-image: '../img/sprite/sprite.png';
$icon-checkbox-off: (46px, 0px, -46px, 0px, 16px, 15px, 280px, 22px, '../img/sprite/sprite.png', 'icon-checkbox-off', );
$icon-checkbox-on-name: 'icon-checkbox-on';
$icon-checkbox-on-x: 64px;
$icon-checkbox-on-y: 0px;
$icon-checkbox-on-offset-x: -64px;
$icon-checkbox-on-offset-y: 0px;
$icon-checkbox-on-width: 16px;
$icon-checkbox-on-height: 15px;
$icon-checkbox-on-total-width: 280px;
$icon-checkbox-on-total-height: 22px;
$icon-checkbox-on-image: '../img/sprite/sprite.png';
$icon-checkbox-on: (64px, 0px, -64px, 0px, 16px, 15px, 280px, 22px, '../img/sprite/sprite.png', 'icon-checkbox-on', );
$icon-home-active-name: 'icon-home-active';
$icon-home-active-x: 259px;
$icon-home-active-y: 0px;
$icon-home-active-offset-x: -259px;
$icon-home-active-offset-y: 0px;
$icon-home-active-width: 21px;
$icon-home-active-height: 20px;
$icon-home-active-total-width: 280px;
$icon-home-active-total-height: 22px;
$icon-home-active-image: '../img/sprite/sprite.png';
$icon-home-active: (259px, 0px, -259px, 0px, 21px, 20px, 280px, 22px, '../img/sprite/sprite.png', 'icon-home-active', );
$icon-home-name: 'icon-home';
$icon-home-x: 236px;
$icon-home-y: 0px;
$icon-home-offset-x: -236px;
$icon-home-offset-y: 0px;
$icon-home-width: 21px;
$icon-home-height: 20px;
$icon-home-total-width: 280px;
$icon-home-total-height: 22px;
$icon-home-image: '../img/sprite/sprite.png';
$icon-home: (236px, 0px, -236px, 0px, 21px, 20px, 280px, 22px, '../img/sprite/sprite.png', 'icon-home', );
$icon-inbox-active-name: 'icon-inbox-active';
$icon-inbox-active-x: 174px;
$icon-inbox-active-y: 0px;
$icon-inbox-active-offset-x: -174px;
$icon-inbox-active-offset-y: 0px;
$icon-inbox-active-width: 18px;
$icon-inbox-active-height: 22px;
$icon-inbox-active-total-width: 280px;
$icon-inbox-active-total-height: 22px;
$icon-inbox-active-image: '../img/sprite/sprite.png';
$icon-inbox-active: (174px, 0px, -174px, 0px, 18px, 22px, 280px, 22px, '../img/sprite/sprite.png', 'icon-inbox-active', );
$icon-inbox-name: 'icon-inbox';
$icon-inbox-x: 154px;
$icon-inbox-y: 0px;
$icon-inbox-offset-x: -154px;
$icon-inbox-offset-y: 0px;
$icon-inbox-width: 18px;
$icon-inbox-height: 22px;
$icon-inbox-total-width: 280px;
$icon-inbox-total-height: 22px;
$icon-inbox-image: '../img/sprite/sprite.png';
$icon-inbox: (154px, 0px, -154px, 0px, 18px, 22px, 280px, 22px, '../img/sprite/sprite.png', 'icon-inbox', );
$icon-list-active-name: 'icon-list-active';
$icon-list-active-x: 118px;
$icon-list-active-y: 0px;
$icon-list-active-offset-x: -118px;
$icon-list-active-offset-y: 0px;
$icon-list-active-width: 16px;
$icon-list-active-height: 14px;
$icon-list-active-total-width: 280px;
$icon-list-active-total-height: 22px;
$icon-list-active-image: '../img/sprite/sprite.png';
$icon-list-active: (118px, 0px, -118px, 0px, 16px, 14px, 280px, 22px, '../img/sprite/sprite.png', 'icon-list-active', );
$icon-list-name: 'icon-list';
$icon-list-x: 136px;
$icon-list-y: 0px;
$icon-list-offset-x: -136px;
$icon-list-offset-y: 0px;
$icon-list-width: 16px;
$icon-list-height: 14px;
$icon-list-total-width: 280px;
$icon-list-total-height: 22px;
$icon-list-image: '../img/sprite/sprite.png';
$icon-list: (136px, 0px, -136px, 0px, 16px, 14px, 280px, 22px, '../img/sprite/sprite.png', 'icon-list', );
$icon-log-out-name: 'icon-log-out';
$icon-log-out-x: 0px;
$icon-log-out-y: 0px;
$icon-log-out-offset-x: 0px;
$icon-log-out-offset-y: 0px;
$icon-log-out-width: 12px;
$icon-log-out-height: 14px;
$icon-log-out-total-width: 280px;
$icon-log-out-total-height: 22px;
$icon-log-out-image: '../img/sprite/sprite.png';
$icon-log-out: (0px, 0px, 0px, 0px, 12px, 14px, 280px, 22px, '../img/sprite/sprite.png', 'icon-log-out', );
$icon-scales-active-name: 'icon-scales-active';
$icon-scales-active-x: 100px;
$icon-scales-active-y: 0px;
$icon-scales-active-offset-x: -100px;
$icon-scales-active-offset-y: 0px;
$icon-scales-active-width: 16px;
$icon-scales-active-height: 13px;
$icon-scales-active-total-width: 280px;
$icon-scales-active-total-height: 22px;
$icon-scales-active-image: '../img/sprite/sprite.png';
$icon-scales-active: (100px, 0px, -100px, 0px, 16px, 13px, 280px, 22px, '../img/sprite/sprite.png', 'icon-scales-active', );
$icon-scales-name: 'icon-scales';
$icon-scales-x: 82px;
$icon-scales-y: 0px;
$icon-scales-offset-x: -82px;
$icon-scales-offset-y: 0px;
$icon-scales-width: 16px;
$icon-scales-height: 13px;
$icon-scales-total-width: 280px;
$icon-scales-total-height: 22px;
$icon-scales-image: '../img/sprite/sprite.png';
$icon-scales: (82px, 0px, -82px, 0px, 16px, 13px, 280px, 22px, '../img/sprite/sprite.png', 'icon-scales', );
$icon-student-active-name: 'icon-student-active';
$icon-student-active-x: 30px;
$icon-student-active-y: 0px;
$icon-student-active-offset-x: -30px;
$icon-student-active-offset-y: 0px;
$icon-student-active-width: 14px;
$icon-student-active-height: 17px;
$icon-student-active-total-width: 280px;
$icon-student-active-total-height: 22px;
$icon-student-active-image: '../img/sprite/sprite.png';
$icon-student-active: (30px, 0px, -30px, 0px, 14px, 17px, 280px, 22px, '../img/sprite/sprite.png', 'icon-student-active', );
$icon-student-name: 'icon-student';
$icon-student-x: 14px;
$icon-student-y: 0px;
$icon-student-offset-x: -14px;
$icon-student-offset-y: 0px;
$icon-student-width: 14px;
$icon-student-height: 17px;
$icon-student-total-width: 280px;
$icon-student-total-height: 22px;
$icon-student-image: '../img/sprite/sprite.png';
$icon-student: (14px, 0px, -14px, 0px, 14px, 17px, 280px, 22px, '../img/sprite/sprite.png', 'icon-student', );
$spritesheet-width: 280px;
$spritesheet-height: 22px;
$spritesheet-image: '../img/sprite/sprite.png';
$spritesheet-sprites: ($icon-card-active, $icon-card, $icon-checkbox-off, $icon-checkbox-on, $icon-home-active, $icon-home, $icon-inbox-active, $icon-inbox, $icon-list-active, $icon-list, $icon-log-out, $icon-scales-active, $icon-scales, $icon-student-active, $icon-student, );
$spritesheet: (280px, 22px, '../img/sprite/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
