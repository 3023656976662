am-multiselect .dropdown-menu{
    padding-left: 5px;
    padding-right: 5px;
}

am-multiselect .dropdown-menu > li > a {
    padding: 3px 10px;
    cursor:pointer;
}

am-multiselect .dropdown-menu > li.selected {
    background-color: #ADD8E6;
}