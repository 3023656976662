.header {
	background: $main_bg_color;

	.logo-wrapper {
		text-align: center;
		padding: 10px 25px;
		float: left;
		width: $sidebar_width;

		img {
			width: 80px;
		}
	}

	.section {
		display: table;
		width: calc(100% - #{$sidebar_width});
		height: $header_initial_height;

		.title {
			padding: 12px 16px;
			display: table-cell;
			vertical-align: middle;
			width: 100%;

			h1 {
				padding: 0;
				margin: 0;
			}

			.school-name {
				font-weight: 700;
			}

			span {
				color: lighten(desaturate(adjust-hue($second_font_color, -229deg), 10), 45);
			}
		}

		.options {
			display: table-cell;
			white-space: nowrap;
			padding: 10px 30px 10px 10px;
			vertical-align: middle;

			.username {
				border-right: 1px solid; 
				padding-right: 10px; 
				margin-right: 5px;
			}

			a {
				font-size: $big_text_size;
				color: lighten(desaturate($main_font_color, 1), 15);

				i {
					margin-left: 2px;
				}
			}
		}
	}
}