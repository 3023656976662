.modal-dialog {
	width: 720px;

	&.modal-lg {
	    width: 900px;
	}

	&.modal-sm {
	    width: 320px;
	}
}

.modal {
	@extend %table-styles;

	.table-wrapper {
		box-shadow: none;
		border: none;
		max-height: none;
		overflow: visible;

		&.imported-teachers {
			td:first-child {
				padding-left: 0;
			}

			td:last-child {
				padding-right: 0;
			}

			.input-group {
				width: 100%;

				.input-group-btn {
					width: 180px;
					overflow: hidden;

					.btn {
						position: absolute;
						top: 0px;
						right: 0;
						transition: all ease 300ms;

						&.ng-hide {
							right: -100%;
						}
					}
				}
			}
		}
	}

	.modal-content {
		padding: 40px;

		.modal-header {
			padding: 0 0 15px 0;

			+ .modal-body {
				padding: 15px 0;
			}
		}

		.modal-body {
			padding: 0 0 15px;
		}

		.modal-footer {
			padding: 15px 0 0 0;

			.btn {
				min-width: 10px;
			}
		}

		.student-main-info {
			.first-row {
				> * {
					display: inline-block;

					+ * {
						margin-left: 10px;
					}
				}

				h2 {
					font-size: $huge_text_size;
				}
			}

			.second-row {
				color: $table_header_font_color;

				> * {
					display: inline-block;

					+ * {
						margin-left: 10px;
					}
				}

				span {
					color: $main_font_color;
				}
			}
		}
	}

	&.new-student, &.edit-student,
	&.new-student-byTeacher, &.edit-student-byTeacher {
		.modal-content {
			.modal-body {
				div.form-group {
					height: 34px;

					input + label {
						margin: 10px 0 10px 5px;
					}
				}
			}
		}
	}
}