.content {
    padding: 15px;

    .study-years {
        float: left;
        margin-right: 10px;
    }

    @extend %table-styles;



    &-wrapper {
        &_inner {
            height: calc(100% - 70px);
            overflow: auto;
            margin: 15px 0;

            .pending-completion, .already-completed {
                margin-bottom: 20px;
            }

            &.student-card-fields {
                height: calc(100% - 50px) !important;
            }
        }
    }

    .buttons-group-line {
        position: relative;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background: $table_border_color;
        }

        .buttons-wrapper {
            position: relative;
            display: inline-block;
            padding: 0 10px;
            background: $second_bg_color;

            .btn {
                min-width: 200px;
            }
        }
    }

    .balancing-list {
        height: calc(100% - 50px);
        overflow: auto;
        margin: 10px -10px 0 -10px;
        padding: 0 10px;

        .classroom-wrapper {
            margin-bottom: 10px;
            position: relative;

            .classroom-options {
                margin: 0;
                border-radius: 3px 3px 0 0;
                border-left: 1px solid $table_border_color;
                border-right: 1px solid $table_border_color;
                border-top: 1px solid $table_border_color;
                border-bottom: none;
                background: $table_header_bg_color;

                .balance {
                    color: $main_bg_color;

                    &.positive {
                        background: $success_button_color;
                    }

                    &.negative {
                        background: $danger_button_color;
                    }
                }

                th,
                .like-th {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                th {
                    &:last-child {
                        width: 100%;
                    }
                }

                // &.open {
                // 	border-radius: 3px 3px 0 0;
                // 	border-bottom: none;
                // }

                + .table-wrapper {
                    margin-top: 0;
                    border-radius: 0 0 3px 3px;
                }
            }

            .controls {
                position: absolute;
                right: 16px;
                top: 6px;
            }
        }
    }

    .table-wrapper {
        max-height: none;
        overflow: visible;
        background: $main_bg_color;

        .lock-cell {
            width: 55px;

            .fa {
                font-size: 14px;
            }
        }

        .dropdown-toggle {
            padding-top: 2px;
            padding-bottom: 2px;
            line-height: 24px;
            height: 29px;
        }

        .dropdown-menu {
            left: auto;
            right: 0;
        }
    }

    .card-progress {
        height: calc(100% + 40px);

        .pull-right {
            span + span {
                margin-left: 10px;
            }

            .customized-progressbar {
                margin-right: 16px;
            }
        }

        span.completed {
            color: $success_button_color;
        }
    }

    .fullwidth-tabs {
        margin: -15px;
        height: calc(100% + 15px) !important;
    }

    &.v-scroll {
        overflow-y: auto;
    }
}

td.scrolled-table-wrapper {
    padding: 0 !important;
    width: 100%;
    position: relative;

    .scrolled-holder {
        width: 1px; // it's a trick!
        min-width: calc(100% + 1px); // a hack for Safari on Mac
        overflow-x: auto;
        position: absolute;
        left: 0;
        top: 0;

        &.has-no-scroll {
            margin: 0;
        }
    }

    table.scrolled-table {
        td, th {
            min-width: 85px;
        }
    }
}

.scrolled-main-content {
    overflow-y: auto;
    margin-top: 15px;
    margin-bottom: 15px;

    &.with-tabs {
        height: calc(100% - 20px) !important;

        &.with-search-buttons {
            height: calc(100% - 50px) !important;
        }
    }
}

.sswww {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.sswww > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
}
.sswww  > li > a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #2e6da4;
    background-image: linear-gradient(to bottom,#337ab7 0,#2e6da4 100%);
    background-repeat: repeat-x;
    outline: 0;
}

.dsswww {
    position: absolute;
/*    top: 100%;
    left: 0;*/
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.dsswww > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
}

.dsswww  > li > a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #2e6da4;
    background-image: linear-gradient(to bottom,#337ab7 0,#2e6da4 100%);
    background-repeat: repeat-x;
    outline: 0;
}

.form-group-search{
    position: relative;
}
.www-create-student,
.dwww-create-student{
    display: none;
}


.tab-students-list {
    height: 100%;
}
.students-list-table-wrapper {
    height: calc(100% - 87px) !important;
    overflow-y: scroll !important;
}