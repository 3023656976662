label {
    font-weight: normal;
    line-height: normal;
}

.dropdown-menu > .disabled > a {
    &:hover,
        &:focus {
        text-decoration: none;
        background-color: transparent;
        background-image: none; // Remove CSS gradient
        cursor: not-allowed;
    }
}

.btn-group {
    .btn {
        min-width: 0;
    }
}

.btn {
    border: none;
    line-height: 20px;
    padding: 7px 12px;
    border-radius: 3px;
    box-shadow: none;
    font-weight: 700;
    min-width: 160px;
    font-size: $normal_text_size;

    &.btn-primary {
        background: $primary_button_color;
    }
    &.btn-success {
        background: $success_button_color;
    }
    &.btn-danger {
        background: $danger_button_color;
    }

    &.fixed-medium {
        min-width: $fixed_medium_width;
        width: $fixed_medium_width;
    }

    &.btn-sm-width {
        min-width: 80px;
    }

    &.not-clickable {
        outline: none;
        cursor: default;

        &:active {
            outline: none;
            box-shadow: none;
        }

        &.btn-primary:active {
            background: $primary_button_color;
        }
        &.btn-success:active {
            background: $success_button_color;
        }
        &.btn-danger:active {
            background: $danger_button_color;
        }
    }

    + .btn, + .dropdown {
        margin-left: 7px;
    }

    &.tag-button {
        width: auto;
        min-width: 10px;
        margin: 0 7px 7px 0;
    }
}

input {
    &[type="checkbox"] {
        display: none;

        + label {
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 0 5px;
            cursor: pointer;
            background: url($icon-checkbox-off-image);
            background-position: $icon-checkbox-off-offset-x $icon-checkbox-off-offset-y;
            width: $icon-checkbox-off-width;
            height: $icon-checkbox-off-height;

            + label {
                margin-left: 5px;
                cursor: pointer;
                max-width: calc(100% - 35px);
                vertical-align: top;
            }
        }

        &:checked + label, + label.input-checkbox.checked {
            background-position: $icon-checkbox-on-offset-x $icon-checkbox-on-offset-y;
        }

        &[disabled] + label {
            cursor: not-allowed;
            opacity: 0.7;
        }
    }

    &[type="file"] {
        visibility: hidden;
        position: absolute;
    }

    &.small-input {
        width: 45px !important;
        padding: 6px 10px;
    }
}

label {
    &.text-label {
        margin: 0;
        font-weight: inherit;
    }
}

textarea {
    max-width: 100%;
}

.ng-invalid-required {
    &.ng-touched {
        border-color: $danger_button_color;
    }
}

.ng-invalid {
    &.ng-touched {
        border-color: $danger_button_color;
    }
}

.manage-buttons, .custom-buttons {
    background: none;
    border-radius: 3px;
    padding: 2px 6px;
    vertical-align: middle;
    color: $link_color;
    font-size: $normal_text_size;
    line-height: $normal_text_size + 6;
    border: 2px solid $link_color;

    &.delete {
        color: $danger_button_color;
        border-color: $danger_button_color;

        .glyphicon-remove, .glyphicon-trash {
            top: 2px;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.7;
    }

    &.success {
        color: $success_button_color;
        border-color: $success_button_color;
    }
}

.form-table {
    display: table;

    > * {
        display: table-cell;

        &:first-child {
            padding-right: 10px;
        }
    }

    &.grades {
        > label {
            width: 100%;
        }

        > input {
            width: 85px;
            text-align: right;
        }
    }
}

.custom-multiselect {
    display: inline-block;
    vertical-align: middle;
    min-width: 100px;

    &.ng-dirty.ng-invalid.ng-invalid-required {
        .dropdown-multiselect, .dropdown {
            .dropdown-toggle {
                border-color: $danger_button_color;
            }
        }
    }

    &.gender-select {
        width: 100px; // fix because Chrome
    }

    &.fixed-medium {
        width: $fixed_medium_width;
    }

    &.drop-to-top {
        .dropdown-multiselect.open, .dropdown.open {
            .dropdown-toggle {
                border-radius: 0 0 4px 4px;
            }
        }

        .dropdown-menu {
            top: auto;
            bottom: calc(100% - 1px);
            border-radius: 4px 4px 0 0;
            box-shadow: 0 -6px 12px rgba(0,0,0,.175);
        }
    }
}

.dropdown-multiselect, .dropdown {
    position: relative;
    max-width: 100%;

    .dropdown-toggle {
        position: relative;
        cursor: pointer;
        padding-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;

        .caret {
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -2px;
            border-top-color: #b2b2b2;
        }
    }

    &.open {
        .dropdown-toggle {
            border-radius: 4px 4px 0 0;
        }
    }

    ul.dropdown-menu {
        min-width: 100%;
        max-height: 60vh;
        overflow: auto !important;
        border-color: #CCCCCC;
        margin-top: -1px;
        border-radius: 0 0 4px 4px;
        padding: 0;

        > li {
            cursor: pointer;

            > a {
                padding: 2px 30px 2px 10px;
                outline: 0;
                position: relative;

                input {
                    + label {
                        min-height: 0;
                        padding: 0;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        margin-top: -#{$icon-checkbox-off-height / 2};
                    }
                }
            }
        }
    }
}

.custom-tabs {
    .nav {
        &.nav-tabs {
            padding: 10px 15px 0 15px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
            border-top: 1px solid $table_header_bg_color;
            background: $main_bg_color;

            > li {
                a {
                    border: none;
                    outline: 0;
                    padding-right: 40px;
                    color: $main_font_color;

                    &:hover {
                        background: none;
                        color: $link_hover_color;
                    }
                }

                &.active {
                    > a {
                        position: relative;
                        z-index: 1;
                        border: none;
                        color: $link_hover_color;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -1px;
                            top: -6px;
                            z-index: -1;
                            transform: perspective(9px) rotateX(2deg);
                            transform-origin: bottom left;
                            border-radius: 10px 10px 0 0;
                            box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.1);
                            border: 1px solid $table_border_color;
                            border-bottom: none;
                            background: $second_bg_color;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 10px;
                            left: 0px;
                            bottom: -8px;
                            border-radius: 20px 20px 0 0;
                            background: $second_bg_color;
                        }
                    }
                }
            }
        }
    }
    .tab-content {
        padding: 15px;

        .content-wrapper_inner {
            .table-wrapper {
                margin: 0;
            }
        }
    }

    &.v-scroll {
        height: 100%;
        overflow: hidden;

        .tab-content {
            height: calc(100% - 40px);

            > .tab-pane, .content-wrapper_inner {
                height: 100%;

                &.school-info-edit-wrapper {
                    height: calc(100% - 70px);
                }

                .table-wrapper {
                    max-height: calc(100% - 60px);

                    &.wysiwyg {
                        max-height: none;

                        table tr td:last-child {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.glyphicon {
    &.glyphicon-ok {
        color: $primary_button_color;
    }

    &.glyphicon-remove, &.glyphicon-trash {
        color: $danger_button_color;
    }
}

.customized-progressbar {
    display: inline-block;
    width: 150px;
    margin: 0;
    height: 27px;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid $table_border_color;
    background: $main_bg_color;

    .progress-bar {
        box-shadow: none;
        background: $progressbar_color;
    }

    &.total {
        .progress-bar {
            background: $success_button_color;
        }
    }

    &.completed {
        .progress-bar {
            background: darken(desaturate($progressbar_color, 1), 11);
        }
    }
}
span + .customized-progressbar {
    margin-left: 10px;
}

.customized-accordeon {
    .panel-group {
        .panel {
            background: none;
            box-shadow: none;
            border: none;

            .panel-heading {
                padding: 0;
                background: none;

                .panel-title {
                    font-size: $big_text_size;

                    .glyphicon.pull-left {
                        margin-right: 10px;
                    }
                }
            }

            .panel-collapse {
                > .panel-body {
                    border: none;
                    padding: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}

.import-warnings {
    max-height: 200px;
    overflow-y: scroll;
}