@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@include sprites($spritesheet-sprites);

html {
	height: 100%;
}

body {
	background: url('../img/main-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow-y: hidden;
	overflow-x: auto;
	font-family: $main_font;
	font-size: $normal_text_size;
	color: $main_font_color;

	&.landing {
		overflow: auto;
		background: none;

		.container.main {
			width: 100%;
			height: auto;
			padding: 0 !important;
			max-width: none;
			min-width: 0;

			&:before {
				display: none;
			}
		}
	}
}

a {
	color: $link_color;

	&:hover, &:active {
		color: $link_hover_color;
	}
}

.block-with-shadow {
	box-shadow: 0px 18px 73px rgba(0, 0, 0, 0.75);
}

.container.main {
	width: 1200px;
	height: 100vh;
	max-width: 100%;
	min-width: 991px;

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
		width: 0;
		margin-right: -0.30em;
	}
}

h1 {
	font-weight: 700;
	font-size: $huge_text_size;
}
h2 {
	font-weight: normal;
	margin-top: 10px;
	font-size: $big_text_size + 3;
}

.app-wrapper {
	display: inline-block;
	vertical-align: middle;
	width: 100%;

	&.main-wrapper {
		height: 90%;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		margin: 0;
		background: $main_bg_color;

		#content-wrapper {
			height: calc(100% - #{$header_initial_height});
			background: $second_bg_color;

			.sidebar {
				float: left;
			}

			.content {
				float: left;
				height: 100%;
				width: calc(100% - #{$sidebar_width});

				> div:first-child {
					// height: 100%;
				}
			}
		}
	}
}

.user-teacher {
	.app-wrapper.main-wrapper {
		#content-wrapper {
			.content {
				float: none;
				width: 100%;

				.btn {
					min-width: 140px;
				}
			}
		}
	}
}

table {
	&.common-table {
		width: 100%;
	}

	&.two-cells {
		td:first-child {
			padding-right: 5px;
		}

		td:last-child {
			padding-left: 5px;
		}
	}
}

.icons {
	display: inline-block;
}

.help {
	.text-muted {
		margin-top: 35px;
	}
}

.bottom-popup {
    position: fixed;
    padding: 10px;
    left: 50%;
    bottom: 0;
    text-align: center;
    transform: translateX(-50%);
    transition: all ease 500ms;
    border-radius: 4px 4px 0 0;
    border: 1px solid $table_border_color;
    background: $main_bg_color;

    &.assign {
    	.btn {
	        padding-top: 7px;
	        padding-bottom: 7px;
	        margin-left: 10px;
	    }
	}

    &.ng-enter {
		bottom: -60px;
    }
    &.ng-enter-active {
    	bottom: 0;
    }
    &.ng-leave {
		bottom: 0;
    }
    &.ng-leave-active {
    	bottom: -80px;
    }
}

.contact-us {
    position: fixed;
    right: 5%;
    bottom: 0;
    width: 300px;
    transition: all 500ms;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transform: translateY(91%);

    .contact-us-form {
	    background: $main_bg_color;
	    padding: 10px 15px 0;
	    overflow: hidden;
	    float: right;
	    width: 100%;
	    border: 1px solid $table_border_color;

	    textarea {
	        resize: vertical;
	    }
	}

	.contact-us-button {
	    padding: 10px 15px;
	    text-align: center;
	    cursor: pointer;
	    float: right;
	    height: 43px;
	    width: 140px;
	    border-radius: 4px 4px 0 0;
	    transition: all 300ms;
	    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	    border-bottom: 0;
	    border: 1px solid $primary_button_color;
	    background: $primary_button_color;
	    color: $main_bg_color;
	}

	&.show {
    	transform: translateY(0);

    	.contact-us-button {
    		width: 100%;
    	}
    }
}

#landing {
	.plan-badge {
	    position: absolute;
	    top: -15px;
	    right: -15px;
	    overflow: hidden;
	    width: 125px;
	    height: 125px;

	    &:before, &:after {
	    	content: '';
		    background: #11790C;
		    position: absolute;
		    width: 15px;
		    z-index: 1;
		    height: 15px;
		    top: 0;
		    left: 11px;
	    }

	    &:after {
	    	top: auto;
		    left: auto;
		    bottom: 11px;
		    right: 0;
	    }

	    > span {
	    	    background: #17a510;
			    color: #fff;
			    transform: rotate(45deg);
			    transform-origin: 0 0;
			    text-transform: uppercase;
			    font-size: 10px;
			    display: block;
			    position: absolute;
			    top: -60px;
			    right: -140px;
			    z-index: 2;
			    padding: 5px 100px 3px;
	    }
	}
}