$main_font: 'Montserrat', sans-serif;

$hugest_text_size: 40px;
$huge_text_size: 30px;
$big_text_size: 17px;
$normal_text_size: 15px;
$small_text_size: 10px;

$header_initial_height: 80px;

$sidebar_width: 170px;
$sidebar_font_color: #ffffff;
$sidebar_bg_color: #343c60;

$main_font_color: #3d3d3f;
$second_font_color: #2f313a;
$link_color: #55b2e8;
$link_hover_color: #1c90cc;

$main_bg_color: #ffffff;
$second_bg_color: #eaedf3;

$table_border_color: #d3d5db;
$table_header_bg_color: #f8f8f9;
$table_header_font_color: #979797;

$primary_button_color: #1c90cc;
$success_button_color: #2ecc71;
$danger_button_color: #D9534F;

$progressbar_color: #a9d7f3;

$spacer-x: 1rem;
$spacer-y: 1rem;

// Buttons and multiselect
$fixed_large_width: 195px;
$fixed_medium_width: 130px;
$fixed_small_width: 65px;

%table-styles {
	.table-wrapper {
		margin: 22px 0;
		border-radius: 3px;
		max-height: 87%;
		overflow: auto;
		box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
		border: 1px solid $table_border_color;

		table {
			width: 100%;
			background: $main_bg_color;

			tr {
				border-top: 1px solid $table_border_color;

				th {
					text-transform: uppercase;
					vertical-align: middle;
					padding: 5px;
					height: 34px;
					font-size: $small_text_size;
					line-height: $small_text_size + 2;
					color: $table_header_font_color;
					background: $table_header_bg_color;

					span {
						font-weight: 700;
						color: $primary_button_color;
					}

					&:first-child {
						padding-left: 15px;
					}
					&:last-child {
						padding-right: 15px;
						text-align: right;
					}
				}

				td {
					vertical-align: middle;
					padding: 5px;
					height: 48px;

					&.manage-cell {
						white-space: nowrap;
					}

					.grade-wrapper {
						width: 110px;

						span {
							display: inline-block;
							margin-right: 10px;
							font-size: $normal_text_size - 1;
						}
					}

					&:first-child {
						padding-left: 15px;
					}
					&:last-child {
						padding-right: 15px;
						text-align: right;
					}
				}

				&:first-child {
					border: none;
				}
			}
		}

		.like-th {
			text-transform: uppercase;
			vertical-align: middle;
			padding: 5px;
			height: 34px;
			display: inline-block;
			font-weight: bold;
			font-size: $small_text_size;
			line-height: $small_text_size + 2;
			color: $table_header_font_color;
			background: $table_header_bg_color;
		}
	}
}