.privacyAndTerms-wrapper {
	text-align: center;

	.privacyAndTerms {
		width: 900px;
		display: inline-block;
		text-align: left;
		max-height: 93vh;
		overflow: auto;
		background: $main_bg_color;

		.header {
			text-align: center;
			padding: 25px;
		}

		h1 {
			margin-top: 0;
			margin-bottom: 15px;
			font-size: $hugest_text_size;
		}

		.content {
			padding: 40px 40px 55px;
			font-size: $normal_text_size + 1;

		}
	}
}