.text-center {
	text-align: center !important;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}

.text-success {
    color: $success_button_color;
}
.text-danger {
    color: $danger_button_color;
}

.hugest-text {
	font-size: $hugest_text_size;
}
.huge-text {
	font-size: $huge_text_size;
}
.big-text {
	font-size: $big_text_size;
}
.normal-text {
	font-size: $normal_text_size;
}
.small-text {
	font-size: $small_text_size;
}
.smallest-text {
	font-size: $small_text_size - 2;
}

.word-break {
    word-break: break-all !important;
}

.two-in-row {
	width: 48%;
}

.dib {
	display: inline-block;

	+ .dib {
		margin-left: 10px;
	}
}
.dblock {
	display: block !important;
}

.large-width {
    width: $fixed_large_width !important;
}
.medium-width {
    width: $fixed_medium_width !important;
}
.small-width {
    width: $fixed_small_width !important;
}

.vat {
	vertical-align: top;
}
.vam {
	vertical-align: middle;
}
.vab {
	vertical-align: bottom;
}

.w-a {
	width: auto !important;
	min-width: 10px !important;
}

.col-vam {
	display: table;
	width: 100%;

	> div[class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}

.one-line {
	white-space: nowrap;
}

.soaring {
	position: absolute;
}

.no-scroll {
    overflow: visible !important;
}

.no-max-height {
    max-height: none !important;
}

@for $i from 0 through 3 {
	.p-l-#{$i} {
    	padding-left: ($spacer-x * $i) !important;
    }
    .p-r-#{$i} {
    	padding-right: ($spacer-x * $i) !important;
    }
    .p-t-#{$i} {
    	padding-top: ($spacer-y * $i) !important;
    }
    .p-b-#{$i} {
    	padding-bottom: ($spacer-y * $i) !important;
    }
    .p-a-#{$i} {
    	padding: ($spacer-y * $i) ($spacer-x * $i) !important;
    }

    .m-l-#{$i} {
    	margin-left: ($spacer-x * $i) !important;
    }
    .m-r-#{$i} {
    	margin-right: ($spacer-x * $i) !important;
    }
    .m-t-#{$i} {
    	margin-top: ($spacer-y * $i) !important;
    }
    .m-b-#{$i} {
    	margin-bottom: ($spacer-y * $i) !important;
    }
    .m-a-#{$i} {
    	margin: ($spacer-y * $i) ($spacer-x * $i) !important;
    }
}