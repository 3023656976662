.sidebar {
	height: 100%;
	position: relative;
	padding-bottom: 100px;
	width: $sidebar_width;
	min-width: $sidebar_width;
	background: $sidebar_bg_color;

	.menu {
		margin: 0;
		padding: 10px 0;

		li {
			list-style: none;

			&.active {
				a {
					background: darken(saturate(adjust-hue($sidebar_bg_color, 1deg), 3), 5);
					
					.icons.glyphicon {
						color: #1E7DB3;
					}
					.icon-home {
						background-position: $icon-home-active-offset-x $icon-home-active-offset-y;
					}
					.icon-list {
						background-position: $icon-list-active-offset-x $icon-list-active-offset-y;
					}
					.icon-student {
						background-position: $icon-student-active-offset-x $icon-student-active-offset-y;
					}
					.icon-scales {
						background-position: $icon-scales-active-offset-x $icon-scales-active-offset-y;
					}
					.icon-card {
						background-position: $icon-card-active-offset-x $icon-card-active-offset-y;
					}
					.icon-inbox {
						background-position: $icon-inbox-active-offset-x $icon-inbox-active-offset-y;
					}
				}
			}

			a {
				text-decoration: none;
				line-height: normal;
				text-transform: uppercase;
				display: block;
				padding: 7px 10px 7px 40px;
				position: relative;
				outline: none;
				font-size: $small_text_size + 2;
				color: $sidebar_font_color;

				.icons {
					display: block;
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);

					&.glyphicon {
						font-size: 18px;
						color: #D6D8DF;
					}
				}

				&:hover {
					background: darken(saturate(adjust-hue($sidebar_bg_color, 1deg), 3), 5);
					
					.icons.glyphicon {
						color: #1E7DB3;
					}
					.icon-home {
						background-position: $icon-home-active-offset-x $icon-home-active-offset-y;
					}
					.icon-list {
						background-position: $icon-list-active-offset-x $icon-list-active-offset-y;
					}
					.icon-student {
						background-position: $icon-student-active-offset-x $icon-student-active-offset-y;
					}
					.icon-scales {
						background-position: $icon-scales-active-offset-x $icon-scales-active-offset-y;
					}
					.icon-card {
						background-position: $icon-card-active-offset-x $icon-card-active-offset-y;
					}
					.icon-inbox {
						background-position: $icon-inbox-active-offset-x $icon-inbox-active-offset-y;
					}
				}
			}
		}
	}

	.help-button, .upgrade-button {
		width: calc(100% - 20px);
		min-width: 10px;
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 10px;
		margin: 0px auto !important;
	}

	.upgrade-button {
		bottom: 55px;
	}
}